import {Layout} from "../components/Layout";
import React from 'react';
import {PageMainColumnWrapper} from "../components/PageMainColumnWrapper";
import {graphql} from "gatsby";
import {AboutUsHero} from "../components/AboutUsHero";
import {useIntl} from "gatsby-plugin-react-intl";
import {ContactPersonBlock} from "../components/ContactPersonBlock";
import {rwdImages} from "../functions/rwdImages";
import {MarkdownContent} from "../components/MarkdownContent";
import {AboutUsFirstSection} from "../components/AboutUsFirstSection";
import {AboutUsSecondSection} from "../components/AboutUsSecondSection";
import {createSeoData} from "../functions/createSeoData";

export const query = graphql`query AboutUs($locale: String!) {
  page: contentfulAboutUs(node_locale: {eq: $locale}) {
    title
    heroContent {
      childMarkdownRemark {
        html
      }
    }
    heroImages {
      localFile {
        childImageSharp {
          gatsbyImageData(width: 440, height: 750, placeholder: BLURRED, layout: FIXED)
        }
      }
    }
    firstSectionTitle
    firstSectionContent {
      childMarkdownRemark {
        html
      }
    }
    firstSectionPromoImages {
      localFile {
        childImageSharp {
          gatsbyImageData(width: 450, height: 650, placeholder: BLURRED, layout: FIXED)
        }
      }
    }
    secondSectionContent {
      childMarkdownRemark {
        html
      }
    }
    secondSectionPromoImages {
      localFile {
        childImageSharp {
          gatsbyImageData(width: 515, height: 460, placeholder: BLURRED, layout: FIXED)
        }
      }
    }
    contactPersonTitle
    contactPersonContent {
      childMarkdownRemark {
        html
      }
    }
    contactPerson {
      name
      position
      email
      phone
      bigImage {
        localFile {
          childImageSharp {
            gatsbyImageData(
              width: 680
              height: 1000
              placeholder: BLURRED
              transformOptions: {cropFocus: NORTH}
              layout: FIXED
            )
          }
        }
      }
      mobileBigImage: bigImage {
        localFile {
          childImageSharp {
            gatsbyImageData(
              width: 300
              height: 400
              placeholder: BLURRED
              transformOptions: {cropFocus: NORTH}
              layout: FIXED
            )
          }
        }
      }
    }
    seo {
      ...Seo
    }
  }
}
`;

function renderContactPersonBlock(title, content, contactPerson) {
    if (!contactPerson) {
        return;
    }
    return (
        <ContactPersonBlock
            title={title}
            image={contactPerson.bigImage.localFile}
            mobileImage={contactPerson.mobileBigImage.localFile}
            person={{
                phone: contactPerson.phone,
                email: contactPerson.email,
                name: contactPerson.name,
                position: contactPerson.position
            }}
        >
            <MarkdownContent content={content}/>
        </ContactPersonBlock>
    );
}

export default function AboutUsPage({data, pageContext}) {
    const intl = useIntl();
    return <Layout services={pageContext.services}
                   oldServices={pageContext.oldServices}
                   linkableSubServices={pageContext.linkableSubServices}
                   jobsCount={pageContext.jobsCount}
                   seo={createSeoData(data.page.seo)}
                   title={intl.formatMessage({id: 'menu.aboutUs'})}
                   companyData={pageContext.companyData}
                   lang={pageContext.lang}>
        <PageMainColumnWrapper>
            <AboutUsHero title={data.page.title} content={data.page.heroContent}
                         images={data.page.heroImages.map(x => {
                             return x.localFile.childImageSharp
                         })}/>
            <AboutUsFirstSection title={data.page.firstSectionTitle}
                                 content={data.page.firstSectionContent}
                                 images={data.page.firstSectionPromoImages.map(x => {
                                     return x.localFile.childImageSharp;
                                 })}
            />
            <AboutUsSecondSection content={data.page.secondSectionContent}
                                  images={data.page.secondSectionPromoImages.map(x => {
                                      return x.localFile.childImageSharp;
                                  })}
            />
        </PageMainColumnWrapper>

        {renderContactPersonBlock(data.page.contactPersonTitle, data.page.contactPersonContent, data.page.contactPerson)}
    </Layout>
}

import React from 'react';
import * as styles from './aboutUsHero.module.scss';
import classNames from 'classnames';
import {ClippedImage} from "../ClippedImage";
import {BlockTitle} from "../BlockTitle";
import {MarkdownContent} from "../MarkdownContent";
import {Breadcrumbs} from "../Breadcrumbs";
import {useIntl} from 'gatsby-plugin-react-intl';
import {Typography} from "../Typography";


export function AboutUsHero({title, content, images, className, ...otherProps}) {
    const intl = useIntl();
    return <section className={classNames(styles.root, className)} {...otherProps}>
        <div className={styles.content}>
            <Breadcrumbs className={styles.breadcrumbs}>
                <Breadcrumbs.Item to="/">{intl.formatMessage({id: 'menu.home'})}</Breadcrumbs.Item>
                <Breadcrumbs.Item>{intl.formatMessage({id: 'menu.aboutUs'})}</Breadcrumbs.Item>
            </Breadcrumbs>
            <BlockTitle>{title}</BlockTitle>
            <MarkdownContent component={Typography} content={content}/>
        </div>
        <div className={styles.images}>
            {images.map((x, index) => {
                return <ClippedImage image={x} key={index}
                                     direction="toUp"
                                     className={styles.image}
                                     useCss/>;
            })}
            <div className={styles.foreground}></div>
            <div className={styles.centerImage}></div>
            <div className={styles.background}></div>
        </div>
    </section>
}

import React from 'react';
import * as styles from './AboutUsFirstSection.module.scss';
import {BlockTitle} from "../BlockTitle";
import classNames from 'classnames';
import {MarkdownContent} from "../MarkdownContent";
import {Typography} from "../Typography";
import {GatsbyImage} from "gatsby-plugin-image";

export function AboutUsFirstSection({title, content, className, images, ...otherProps}) {
    return <section className={classNames(className, styles.root)} {...otherProps}>
        <div className={styles.contentWrapper}>
            <BlockTitle>{title}</BlockTitle>
            <MarkdownContent component={Typography} content={content} className={styles.content}/>
        </div>
        <div className={styles.images}>
            {images.map((x, index) => {
                return <GatsbyImage className={styles.image} key={index} {...x} alt="About us"/>
            })}
        </div>
    </section>
}
